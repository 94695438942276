<template>
  <v-container class="container--fluid fill-height generic-page">
    <v-row no-gutters justify="center" align-content="center">
      <v-col cols="12" style="max-width: 500px">
        <v-card class="outlined pa-1">
          <v-card-text>
            <div class="layout column align-center">
              <v-img src="@/assets/logo.png" alt="Logo" width="60" />
              <h1
                class="text-center my-1 mb-10 text-uppercase font-weight-black"
                style="font-size: 1.8rem"
                color="blue-darken-1"
              >
                3NavPlanning
              </h1>
            </div>
            <v-form>
              <v-text-field
                rel="credential"
                v-model="user"
                id="credential"
                name="login"
                type="text"
                :placeholder="$t('global.login_credentials')"
                :rules="[]"
                @keyup.native.enter="login($event)"
                outlined
                hide-details
                class="mb-4"
              >
              </v-text-field>

              <v-text-field
                rel="password"
                v-model="pass"
                id="password"
                name="password"
                :placeholder="$t('users.password')"
                :rules="[]"
                @keyup.native.enter="login($event)"
                outlined
                hide-details
                class="mb-4"
                :type="showPassword ? 'text' : 'password'"
              >
                <template v-slot:append>
                  <v-icon @click="showPassword = !showPassword">
                    {{ showPassword ? "mdi-eye" : "mdi-eye-off" }}</v-icon
                  >
                </template>
              </v-text-field>

              <v-alert
                v-show="loginFailed"
                color="red"
                dark
                style="font-size: 1rem"
                v-if="error"
              >
                {{ error.msg }}
              </v-alert>

              <v-btn
                depressed
                :disabled="loading || loadingEmailVerification"
                :loading="loadingEmailVerification"
                class="mt-3 mb-3"
                color="red lighten-5"
                block
                large
                @click="sendEmailVerification"
                v-if="error && error.code == 'EMAIL_NOT_VERIFIED'"
              >
                {{ $t("login.re_send_verification") }}
              </v-btn>
            </v-form>
            <v-btn
              depressed
              :loading="loading"
              :disabled="loadingEmailVerification"
              color="primary"
              class="white--text mb-4"
              block
              large
              @click="login"
            >
              {{ $t("login.login_button") }}
            </v-btn>
            <v-btn
              depressed
              text
              :disabled="loading || loadingEmailVerification"
              class="mt-3"
              color="grey darken-2"
              block
              dense
              @click="recoverPassword"
              small
            >
              {{ $t("login.forgot_password") }}
            </v-btn>
          </v-card-text>
        </v-card>
        <div style="text-align: center">
          <span color="primary" style="text-align: center; font-size: 0.8em"
            >Powered by 3Maps v{{ version }} -
            {{ new Date().getFullYear() }}</span
          >
        </div>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="showSessionErr"
      top
      class="mt-10"
      timeout="5000"
      absolute
      centered
    >
      <h3 class="text-center">{{ $t("global.session_expired_message") }}</h3>
    </v-snackbar>
  </v-container>
</template>

<script>
import { version } from "/package.json";
export default {
  name: "LoginView",
  data() {
    return {
      loginFailed: false,
      loading: false,
      loadingEmailVerification: false,
      user: "",
      pass: "",
      version: version,
      showPassword: false,
      error: null,
    };
  },
  computed: {
    showSessionErr: {
      get: function () {
        return this.$store.state.user.showSessionErr;
      },
      set: function (newValue) {
        this.$store.commit("setShowSessionErr", newValue);
      },
    },
  },
  methods: {
    login() {
      this.loading = true;
      this.$store
        .dispatch("LOGIN", {
          user: this.user,
          pass: this.pass,
        })
        .then((res) => {
          if (!res.error) {
            this.$router.push("/map");
          } else {
            this.error = res.error;
            this.loginFailed = true;
            this.loading = false;
          }
        })
        .catch(() => {
          this.loginFailed = true;
          this.loading = false;
        });
    },
    recoverPassword() {
      this.$router.push("/forgot-password");
    },
    sendEmailVerification() {
      this.loadingEmailVerification = true;

      this.$store
        .dispatch("SEND_EMAIL_VERIFICATION", {
          user: this.user,
        })
        .then((data) => {
          this.loadingEmailVerification = true;
          if (!data.error) this.$router.push("/verify-your-account");
        })
        .catch(() => {
          this.loadingEmailVerification = true;
        });
    },
  },
};
</script>

<style>
.generic-page {
  background-image: url("../../assets/background.png");
  background-color: #1a202c !important;
  background-repeat: no-repeat;
  --background-attachment: fixed;
  background-position: bottom;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s !important;
  font-size: 14px !important;
  line-height: 32px !important;
  font-family: "Nunito", sans-serif !important;
}

.v-text-field--filled:not(.v-text-field--single-line) input {
  margin-top: 5px !important;
}

input:-webkit-autofill::first-line {
  font-size: 14px;
}
</style>
